var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "jeopardai-team-result-card",
      class: { "jeopardai-team-result-card--active": _vm.current },
      attrs: { "d-flex": "" },
    },
    [
      _c(
        "transition",
        {
          attrs: {
            name: "jeopardai-team-result-card__transition",
            mode: "out-in",
          },
        },
        [
          _vm.flipped && _vm.wager
            ? _c(
                "div",
                {
                  key: "amount",
                  staticClass:
                    "jeopardai-team-result-card__front-side jeopardai-team-result-card__card",
                },
                [
                  _c("Medal", {
                    staticClass: "jeopardai-team-result-card__front-side-medal",
                    attrs: { variant: 2 },
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "jeopardai-team-result-card__jeaopardai-text",
                    },
                    [
                      _vm._v(" FINAL "),
                      _c("span", [_vm._v("JEOPARD")]),
                      _vm._v("AI "),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "jeopardai-team-result-card__content jeopardai-team-result-card__wager",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "jeopardai-team-result-card__wager-number",
                        },
                        [
                          _vm._v(
                            " $" +
                              _vm._s(
                                _vm.addCommasToNumber(
                                  (_vm.submit && _vm.submit.wager) || 0
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c("div", [_vm._v("WAGERED")]),
                    ]
                  ),
                ],
                1
              )
            : _vm.flipped
            ? _c(
                "div",
                {
                  key: "summary",
                  staticClass:
                    "jeopardai-team-result-card__front-side jeopardai-team-result-card__card",
                },
                [
                  _c(
                    "Medal",
                    {
                      staticClass:
                        "jeopardai-team-result-card__front-side-medal",
                      attrs: { variant: _vm.position + 1 },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.position === 1
                              ? "1st"
                              : _vm.position === 2
                              ? "2nd"
                              : _vm.position === 3
                              ? "3rd"
                              : _vm.position + "th"
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "jeopardai-team-result-card__jeaopardai-text",
                    },
                    [
                      _vm._v(" FINAL "),
                      _c("span", [_vm._v("JEOPARD")]),
                      _vm._v("AI "),
                    ]
                  ),
                  _vm.submit
                    ? _c(
                        "div",
                        { staticClass: "jeopardai-team-result-card__submit" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "jeopardai-team-result-card__answer",
                            },
                            [_vm._v(" " + _vm._s(_vm.submit.answer) + " ")]
                          ),
                          _vm.submit.correct
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "jeopardai-team-result-card__status",
                                  class: _vm.submit.correct
                                    ? "jeopardai-team-result-card__status--correct"
                                    : null,
                                },
                                [
                                  _c("i", {
                                    staticClass: "fas fa-check-circle mr-1",
                                  }),
                                  _vm._v("CORRECT "),
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "jeopardai-team-result-card__status",
                                },
                                [
                                  _c("i", {
                                    staticClass: "fas fa-times-circle mr-1",
                                  }),
                                  _vm._v("INCORRECT "),
                                ]
                              ),
                          _c(
                            "div",
                            {
                              staticClass: "jeopardai-team-result-card__gain",
                              class:
                                _vm.submit.amount > -1
                                  ? "jeopardai-team-result-card__gain--positive"
                                  : null,
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.submit.amount > -1
                                      ? "+ $" +
                                          _vm.addCommasToNumber(
                                            _vm.submit.amount
                                          )
                                      : "- $" +
                                          _vm.addCommasToNumber(
                                            _vm.submit.amount * -1
                                          )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "jeopardai-team-result-card__total" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "jeopardai-team-result-card__total-number",
                        },
                        [
                          _vm._v(
                            " $" +
                              _vm._s(_vm.addCommasToNumber(_vm.team.total)) +
                              " "
                          ),
                        ]
                      ),
                      _c("div", [_vm._v("TOTAL")]),
                    ]
                  ),
                ],
                1
              )
            : _c(
                "div",
                {
                  key: "unflipped",
                  staticClass:
                    "jeopardai-team-result-card__back-side jeopardai-team-result-card__card",
                  attrs: { "d-flex": "" },
                },
                [
                  _c("Medal", {
                    staticClass: "jeopardai-team-result-card__back-side-medal",
                    attrs: { variant: 2 },
                  }),
                ],
                1
              ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }