
















































































































import {
  defineComponent,
  computed,
  toRefs,
  ref,
  watch
} from "@vue/composition-api"

import Medal from "./Medal.vue"

import { addCommasToNumber } from "./Games/JeopardAI/helpers"

import useJeopardAiAnswers from "./Games/JeopardAI/use/useJeopardAiAnswers"

export default defineComponent({
  name: "JeopardAiTeamResultCard",
  components: { Medal },
  props: {
    team: {
      type: Object,
      required: true
    },
    flipped: {
      type: Boolean,
      default: false
    },
    current: {
      type: Boolean,
      default: false
    },
    position: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const { team, flipped } = toRefs(props)
    const { answers } = useJeopardAiAnswers()
    const wager = ref(flipped.value ? false : true)

    const submit = computed(() => {
      const [first] = [...answers.value].sort(
        (a, b) => b.timestamp - a.timestamp
      )
      return answers.value.find(
        answer =>
          answer.questionId === first.questionId &&
          answer.teamId === team.value.id
      )
    })

    watch(flipped, value => {
      if (value !== true) {
        wager.value = true
        return
      }
      setTimeout(() => {
        wager.value = false
      }, 5000)
    })

    return { addCommasToNumber, submit, wager }
  },
  watch: {
    flipped(value) {
      if (value !== true) return
      this.$el.scrollIntoView({
        block: "end",
        inline: "center",
        behavior: "smooth"
      })
    }
  }
})
